import { Button, List, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { useProjectConfig } from '../../hooks/useProjectConfig'
import { LanguageType } from '../../Types'
import { sortBy } from 'lodash'
import { LanguageAddedType } from './ProjectCRUD'

/**
 * Created by @author Søren Tramm
 */
type AddLanguageProps = {
  projectLanguages: LanguageAddedType[]
  updatedLanguageList: LanguageAddedType[]
  onUpdateLanguages: (languageSelection: any[]) => void
  onClose?: () => void
}

export const ProjectAddLanguage = ({
  projectLanguages,
  updatedLanguageList,
  onUpdateLanguages,
  onClose,
}: AddLanguageProps) => {
  const { data: projectConfig } = useProjectConfig()
  const [state, setState] = useState<LanguageAddedType[]>([])

  useEffect(() => {
    //
    // Remove the languages which are already added to the project
    //
    const systemLanguages = projectConfig.languages
      .filter((lang: LanguageType) => {
        const currentProjectLang = projectLanguages.filter((projectLang: LanguageAddedType) => {
          return projectLang.isoCode === lang.isoCode //&& projectLang.added !== true
        })
        return currentProjectLang.length === 0 ? true : false
      })

      //
      // Add the state of the newly added languages
      //
      .map((lang) => {
        const newlyAddedItems = updatedLanguageList.filter((selectedItem: LanguageAddedType) => {
          return selectedItem.isoCode === lang.isoCode && selectedItem.added === true
        })

        lang['added'] = newlyAddedItems.length > 0 ? true : false

        return lang as LanguageAddedType
      })

    setState(systemLanguages as LanguageAddedType[])
  }, [projectConfig.languages, projectLanguages, updatedLanguageList])

  //
  // When a language is selected/deselected
  //
  const onChange = (isoCode: string, checked: boolean) => {
    const updated: LanguageAddedType[] = state.map((lang) => {
      if (lang.isoCode === isoCode) {
        return { ...lang, active: checked, added: checked }
      }
      return lang
    })

    setState(updated)
  }

  //
  // Save the selected languages - back to parent component
  //
  const saveSelected = () => {
    onUpdateLanguages(state)
    if (onClose) {
      onClose()
    }
  }

  const cancelSelected = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <div className="container-fluid p-0">
      <div className="row rounded-2">
        <div className="col-12 mb-4 px-2 ">
          <h6 className="mb-0">Add a new language</h6>
          <p>Please select one or more languages to be added to the study </p>
        </div>
      </div>
      <div className="row">
        <List
          className=""
          size="small"
          bordered
          dataSource={sortBy(state, 'name') as LanguageAddedType[]}
          renderItem={(language) => {
            return (
              <List.Item>
                <div className="col-12 py-1 d-flex px-0 justify-content-between">
                  <p className="pe-2 col-7">{language.name}</p>

                  <div className="col-2 d-flex justify-content-end">
                    <Switch
                      className="mt-1"
                      checked={language.added || false}
                      onChange={(checked) => onChange(language.isoCode, checked)}
                      size="small"
                    />
                  </div>
                </div>
              </List.Item>
            )
          }}
        />
      </div>
      <div className="row">
        <div className="col-12 d-flex px-0  mt-3 justify-content-end">
          <Button type="text" className="me-2" onClick={cancelSelected}>
            Cancel
          </Button>
          <Button type="primary" disabled={state.length === 0} onClick={saveSelected}>
            Save selection
          </Button>
        </div>
      </div>
    </div>
  )
}
