import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import React, { useState } from 'react'
import { Button, List, Switch } from 'antd'
import Alert from 'antd/lib/alert'
import { post } from '../../service/API'
import { populateConfig } from '../../util/populateConfig'
import { getFormType } from '../../components/form/formFactory'
import { getProjectViewConfig } from './projectConfig'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { FormLabel } from '../../components/form/FormLabel'
import { openNotification } from '../../components/notifications/openNotification'
import { CRUD_VIEW_TYPE, LanguageType } from '../../Types'
import { JustificationInput } from '../../components/form/JustificationInput'
import { ProjectDrawer } from './ProjectDrawer'
import { ProjectAddLanguage } from './ProjectAddLanguage'
import { sortBy } from 'lodash'
import { useProject } from '../../hooks/useProject'

type Props = {
  closeDrawer: (value) => void
  onClose?: () => void
  viewType?: 'UPDATE' | 'VIEW'
}

export type LanguageAddedType = LanguageType & { added: boolean }

export const ProjectCRUD = ({ onClose, closeDrawer, viewType = 'UPDATE' }: Props) => {
  const { projectId } = useParams() as { projectId: string }

  const { data, mutate } = useProject(projectId)

  const [showAddLanguageDrawer, setShowAddLanguageDrawer] = useState<boolean>(false)
  const [projectLanguagesState, setProjectLanguageState] = useState<LanguageAddedType[]>(data.languages)
  const [updatedLanguageList, setUpdatedLanguageList] = useState<LanguageAddedType[]>([])

  const openAddLanguageDrawer = () => {
    setShowAddLanguageDrawer(true)
  }
  const closeAddLanguageDrawer = () => {
    setShowAddLanguageDrawer(false)
  }
  //
  // When "add language" dreawer is closed
  //
  const updateSelectedLanguagesHandler = (languages: LanguageAddedType[]) => {
    //
    // The languages are the full array of languges on the platform.
    // If an language in the array is active, it is "qued" to the project.
    //

    const arr = languages.filter((lang) => lang.added === true)
    setUpdatedLanguageList(arr)
  }

  const configObj = populateConfig(getProjectViewConfig(), data, viewType)

  const formHook = useForm()

  const { handleSubmit } = formHook
  const [errorState, setErrorState] = useState<any>()

  const onSubmit = (formData, e) => {
    e.preventDefault()

    //
    // This is not super clean
    // here we are removing the "added" property from the array
    // we also set the active to false before sending to server
    //
    const newLanguages: LanguageType[] = updatedLanguageList.map((lang) => {
      lang.active = false
      return { active: false, isoCode: lang.isoCode, name: lang.name }
    })

    const updatedData = { ...formData, languages: newLanguages.concat(projectLanguagesState) }
    const method = viewType === 'UPDATE' ? 'PUT' : 'POST'

    return post(ENDPOINTS.PROJECTS.update(projectId), updatedData, method)
      .then(() => {
        mutate()

        openNotification('Project updated', '', 'happy')

        if (onClose) {
          onClose()
        }
        if (closeDrawer) {
          closeDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        openNotification('Project update failed', '', 'sad')
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
  }

  const onChange = (isoCode: string, checked: boolean) => {
    const update = projectLanguagesState.map((lang) => {
      const copy = { ...lang }
      if (copy.isoCode === isoCode) {
        copy.active = checked
      }
      return copy
    })

    setProjectLanguageState(update)
  }

  return (
    <>
      <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="col-12">
          {configObj.map((item, index) => {
            return getFormType(item, index, formHook)
          })}
          <div className="mt-2">
            <FormLabel label={'Project countries'} />
            <List
              bordered
              className="mt-2"
              size="small"
              dataSource={data.country}
              renderItem={(item: any) => <List.Item>{item.name}</List.Item>}
            />
          </div>

          <div className="mt-2">
            <FormLabel label={'Project active languages'} />
            <List
              className="mt-2 mb-2 px-0"
              size="small"
              bordered
              dataSource={sortBy(projectLanguagesState, 'name')}
              renderItem={(language: any) => (
                <List.Item className="d-flex justify-content-between">
                  <div className=" d-flex">
                    <div className="">{language.name}</div>
                  </div>
                  <Switch
                    className="mt-1"
                    checked={language.active === true}
                    onChange={(checked) => onChange(language.isoCode, checked)}
                    size="small"
                  />
                </List.Item>
              )}
            />
            {updatedLanguageList.length > 0 ? (
              <>
                <FormLabel label={'New languages added'} />
                <List
                  className="mt-2"
                  size="small"
                  bordered
                  dataSource={sortBy(updatedLanguageList, 'name')}
                  renderItem={(language: any) => {
                    return (
                      <List.Item className="d-flex justify-content-between">
                        <div className="">{language.name}</div>
                        <div
                          className="align-self-end me-2 badge text-black border opacity-75"
                          style={{ letterSpacing: 0.8 }}
                        >
                          Just added
                        </div>
                      </List.Item>
                    )
                  }}
                />
              </>
            ) : null}

            <div className="d-flex justify-content-end mt-2" style={{ marginBottom: -5 }}>
              <Button type="default" onClick={openAddLanguageDrawer}>
                Add language
              </Button>
            </div>
          </div>
        </div>

        <div className="col-12 mt-3">
          {/*Justification*/}
          {viewType === CRUD_VIEW_TYPE.UPDATE ? <JustificationInput formHook={formHook} /> : null}
        </div>

        <div className="col-12 mt-3">
          {errorState ? (
            <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
          ) : (
            <></>
          )}
        </div>

        <div className="col-12 d-flex justify-content-end mt-3">
          <Button className="me-2" onClick={navigateBack}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
            Save changes
          </Button>
        </div>
      </form>

      {showAddLanguageDrawer ? (
        <ProjectDrawer setVisible={closeAddLanguageDrawer} projectId={''} width={550} to="." title="Language list">
          <ProjectAddLanguage
            projectLanguages={data.languages}
            updatedLanguageList={updatedLanguageList}
            onUpdateLanguages={updateSelectedLanguagesHandler}
          ></ProjectAddLanguage>
        </ProjectDrawer>
      ) : (
        <></>
      )}
    </>
  )
}
