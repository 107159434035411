/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28/03/2023.
 */
import React, { useCallback, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getQuestionnaireConfig, getTranslateConfig, phaseEligibilityConfig } from './questionnaireConfig'
import { useForm } from 'react-hook-form'
import { post } from '../../service/API'
import { getFormType } from '../../components/form/formFactory'
import { Alert, Button, Select } from 'antd'

import { FormLabel } from '../../components/form/FormLabel'
import { populateConfig } from '../../util/populateConfig'
import { CRUD_VIEW_TYPE, LanguageISOCode, LanguageType, QuestionsTypes, RESOURCE_STATUS, VIEW_TYPE } from '../../Types'
import { ENDPOINTS } from '../../service/ENDPOINTS'

import { useSWRConfig } from 'swr'
import { useProject } from '../../hooks/useProject'
import { LanguageDropdown } from './question/LanguageDropdown'
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons/lib/icons'
import { BODY_PART_LABELS } from '../../constants'
import { useQuestionnaire } from '../../hooks/useQuestionnaire'
import { openNotification } from '../../components/notifications/openNotification'
import { FormViewItem } from '../../components/form/FormViewItem'
import { useProjectConfig } from '../../hooks/useProjectConfig'
import { getDefaultLanguage, getMissingLanguages } from '../../util/languages'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
  allowEdit?: boolean
}

export const questionnaireTypes = {
  STANDARD: 'STANDARD',
  ONBOARDING: 'ONBOARDING',
  ELIGIBILITY: 'ELIGIBILITY',
  GROUP: 'GROUP',
  TRIGGERED: 'TRIGGERED',
} as const

export type QuestionnaireTypes = (typeof questionnaireTypes)[keyof typeof questionnaireTypes]

const questionnaireTypeBaseArr: { value: string; label: string }[] = [
  { value: questionnaireTypes.STANDARD, label: 'Standard' },
  { value: questionnaireTypes.ONBOARDING, label: 'Onboarding' },
  { value: questionnaireTypes.ELIGIBILITY, label: 'Eligibility' },
  { value: questionnaireTypes.TRIGGERED, label: 'Triggered' },
]

export const QuestionnaireCRUD = ({
  closeDrawer,
  onClose,
  viewType = CRUD_VIEW_TYPE.CREATE,
  allowEdit = true,
}: Props) => {
  // pull values from url
  const { projectId, questionnaireId, protocolId, phaseId, groupId } = useParams() as {
    projectId: string
    questionnaireId: string
    protocolId: string
    phaseId: string
    groupId: string
  }

  const isGroup = groupId ? true : false

  const questionnaireTypeArr = isGroup
    ? questionnaireTypeBaseArr.concat([{ value: questionnaireTypes.GROUP, label: 'Group' }])
    : questionnaireTypeBaseArr

  //
  // fetch data
  //
  const { mutate: globalMutate } = useSWRConfig()
  const { data: projectData } = useProject(projectId || '')
  const { data: projectConfig } = useProjectConfig()
  const { data, mutate } = useQuestionnaire(projectId, questionnaireId)

  // langauges
  const supportedLanguages: LanguageType[] = projectData.languages

  const missingLanguages = useMemo(() => {
    return data ? getMissingLanguages(data.name, supportedLanguages) : []
  }, [data, supportedLanguages])

  const defaultLanguage = useMemo(() => {
    return missingLanguages.length > 0
      ? projectData.languages.find((lang) => lang.isoCode === missingLanguages[0])
      : getDefaultLanguage(supportedLanguages)
  }, [missingLanguages, projectData.languages, supportedLanguages])

  // state
  const [currentLanguageIsoCode, setCurrentLanguageIsoCode] = useState<LanguageISOCode>(defaultLanguage.isoCode)

  //
  // Evaluate what type we creating
  // the problem is that "type" has historiclly not been set correctly.
  // which means we need to evaluate the type based on the data we have
  //
  const isEligibility = data?.eligibility ? questionnaireTypes.ELIGIBILITY : questionnaireTypes.STANDARD
  const maybeOnboarding = data?.onboarding ? questionnaireTypes.ONBOARDING : questionnaireTypes.STANDARD
  const maybeOnboardOrEligigility =
    isEligibility === questionnaireTypes.ELIGIBILITY ? questionnaireTypes.ELIGIBILITY : maybeOnboarding

  const evaluatetype = !data ? questionnaireTypes.STANDARD : maybeOnboardOrEligigility
  //
  // isGroup overrides everything and can not be selected manually
  //

  // needs clean upo - mess provided by dennis - since i dont trust the data.type
  const evaluatetypeGroup = isGroup
    ? questionnaireTypes.GROUP
    : data?.type === questionnaireTypes.TRIGGERED
    ? questionnaireTypes.TRIGGERED
    : evaluatetype

  const [questionnaireType, setQuestionnaireType] = useState<QuestionnaireTypes>(evaluatetypeGroup)

  const formHook = useForm({
    defaultValues: {
      type: questionnaireType,
    },
  })
  const { handleSubmit, setValue, reset } = formHook

  // Detect if onboarding - this is lagacy and we should investigate if its need cleanup
  const isOnboarding = questionnaireType === questionnaireTypes.ONBOARDING

  //
  // build config object - to populate form
  //
  const configCommonObj: any = useCallback(() => {
    return data
      ? populateConfig(
          getQuestionnaireConfig(isOnboarding, isGroup, projectConfig.questionnaireRecurrence, questionnaireType),
          data,
          viewType
        )
      : getQuestionnaireConfig(isOnboarding, isGroup, projectConfig.questionnaireRecurrence, questionnaireType)
  }, [data, isGroup, isOnboarding, projectConfig.questionnaireRecurrence, viewType, questionnaireType])

  const eligibilityConfig = data ? populateConfig(phaseEligibilityConfig, data, viewType) : phaseEligibilityConfig

  const translationConfig = data
    ? populateConfig(getTranslateConfig(isOnboarding), data, viewType)
    : getTranslateConfig(isOnboarding)

  const [errorState, setErrorState] = useState<any>()

  const onSubmit = (formData, e) => {
    e.preventDefault()

    const createData = {
      ...formData,
      projectId: projectId,
      protocolId: protocolId,
      phaseId: phaseId,
      onboarding: isOnboarding,
    }

    // not sure if we can send groupId as null and still passing validation
    if (isGroup) {
      createData.groupId = groupId
    }

    const updatedData = {
      ...formData,
      _id: questionnaireId,
      projectId: projectId,
      protocolId: protocolId,
      phaseId: phaseId,
      onboarding: isOnboarding,
    }

    // not sure if we can send groupId as null and still passing validation
    if (isGroup) {
      updatedData.groupId = groupId
    }

    const method = viewType === 'UPDATE' ? 'PUT' : 'POST'
    const sendData = viewType === 'UPDATE' ? updatedData : createData

    console.log(' ####')
    console.log('QuestionnaireCRUD > sendData = ', sendData)

    return post(ENDPOINTS.QUESTIONNAIRES.ACTION(projectId), sendData, method)
      .then((result) => {
        const msg = viewType === 'UPDATE' ? 'Questionnaire updated' : 'Questionnaire created'
        openNotification(msg, '', 'happy')
        mutate()
        globalMutate(ENDPOINTS.PROTOCOLS.GET_ONE(projectId, protocolId))

        if (onClose) {
          onClose()
        }

        if (closeDrawer && viewType !== CRUD_VIEW_TYPE.VIEW) {
          closeDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    console.log('onError = ', err)
    e.preventDefault()
  }

  const navigateBack = () => {
    if (onClose) {
      onClose()
    } else {
      //
      // If you see this error and you dont understand.
      // Have a look in the ProjectDrawer - the problem is caused by nesting
      // and not being able to parse the onClose function
      //
      // Otherwise ask dennis i will explain
      //
      console.error('No close function availble')
    }
  }
  const formatTime = (time: { h: number; m: number }) => {
    return `${time.h < 10 ? `0${time.h}` : time.h}:${time.m < 10 ? `0${time.m}` : time.m}`
  }

  const changeQuestionnaireType = (type, k) => {
    reset()
    setValue('type', type)
    setQuestionnaireType(type)
  }

  const languageSelectHandler = (languageIsoCode: LanguageISOCode) => {
    setCurrentLanguageIsoCode(languageIsoCode)
  }

  return (
    <div key={questionnaireType}>
      {viewType === CRUD_VIEW_TYPE.CREATE || viewType === CRUD_VIEW_TYPE.UPDATE ? (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="row">
            <div className="col-12 mb-2">
              <FormLabel label={'Questionnaire type'} />
              <Select
                disabled={isGroup}
                value={questionnaireType}
                style={{ width: '100%' }}
                onChange={changeQuestionnaireType}
                options={questionnaireTypeArr}
              />
            </div>

            {configCommonObj().map((item, index) => {
              return getFormType(item, index, formHook, projectData.languages, currentLanguageIsoCode)
            })}
          </div>

          <div className="row mt-2">
            <div className="col-12 mb-3 px-4 mt-2">
              <div className="row rounded-2 pb-3" style={{ background: '#e1e6e4' }}>
                <div className="col-12 mt-4 mb-2">
                  <h6 className="mb-1">Language configuration</h6>
                  <p className="opacity-75 mb-0">Configure languages for reminders and questionnaire title</p>
                  <hr className="mt-2 mb-1" />
                </div>
                <div className="col-12  ">
                  {/*
                    --------------------
                    LanguageDropdown should move to  config file
                    --------------------
                  */}
                  <LanguageDropdown
                    supportedLanguages={supportedLanguages}
                    defaultLanguage={defaultLanguage}
                    onChange={languageSelectHandler}
                  />
                  <hr className="mb-0" />
                </div>
                {translationConfig.map((item, index) => {
                  return getFormType(item, index, formHook, projectData.languages, currentLanguageIsoCode)
                })}
              </div>
            </div>
          </div>

          {questionnaireType === questionnaireTypes.ELIGIBILITY ? (
            <EligibilityComp
              eligibilityToggle={questionnaireType === questionnaireTypes.ELIGIBILITY}
              eligibilityConfigObj={eligibilityConfig}
              formHook={formHook}
            ></EligibilityComp>
          ) : null}

          <div className="col-12 mt-3">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>

          <div className="col-12 d-flex justify-content-end mt-3">
            <Button className="me-2" onClick={navigateBack}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
              {viewType === 'CREATE' ? 'Create Questionnaire' : 'Save changes'}
            </Button>
          </div>
        </form>
      ) : (
        /*
        --------------
        VIEW QUESTIONNAIRE
        --------------
        */
        <div className="row">
          {data.status === RESOURCE_STATUS.RETIRED ? (
            <div className="col-12 mb-3">
              <div className="py-3 px-3 rounded-1 d-flex align-items-start" style={{ background: '#ededed' }}>
                <div className="">
                  <InfoCircleOutlined />{' '}
                </div>
                <div className="ms-3">
                  <p className=" fw-bold mb-0">
                    This questionnaire has been retired. <br />
                  </p>
                  <p className=" mb-0">
                    Close the drawer and selected the cloned questionnaire on the group to make changes
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          <div className="col-12 mb-2">
            <div className="">
              <p className="mb-2 p-mini opacity-50 ">
                ID: {data._id} - BelongsTo: {data.belongsTo}
              </p>
            </div>
            <LanguageDropdown
              supportedLanguages={supportedLanguages}
              defaultLanguage={defaultLanguage}
              onChange={languageSelectHandler}
            />
          </div>
          <div className="col-12 mb-1 mt-2">
            <FormLabel label="Type"></FormLabel>
            <h6 className="fw-bold">{data.type ? data.type : '-'}</h6>
          </div>
          <div className="col-12 mb-1">
            <FormLabel label="Internal Title"></FormLabel>
            <h6 className="me-2">{data.internalTitle}</h6>
          </div>
          <div className="col-12 mb-1">
            <FormLabel label="Title"></FormLabel>
            <h6 className="fw-bold">{data.name[currentLanguageIsoCode]}</h6>
          </div>
          <div className="col-12 mb-1">
            <FormLabel label="Status"></FormLabel>
            <h6 className="fw-bold">{data.status}</h6>
          </div>

          {!data?.onboarding && (
            <>
              <div className="col-12 mb-1">
                <FormLabel label="Recurrence type"></FormLabel>
                <h6 className="fw-bold">{!!data.recurrence && data.recurrence.type ? data.recurrence.type : '-'}</h6>
              </div>

              {isGroup || questionnaireType === questionnaireTypes.TRIGGERED ? (
                <div className="col-12 mb-1">
                  <FormLabel label="Questionnaire window in minutes"></FormLabel>
                  <h6 className="fw-bold">{data.recurrence.window} minutes</h6>
                </div>
              ) : (
                <div className="col-12 mb-1">
                  <FormLabel label="Questionnaire window"></FormLabel>

                  <h6 className="fw-bold">
                    {!!data.recurrence && data.recurrence.startAt ? `${formatTime(data.recurrence.startAt)} - ` : '-'}
                    {!!data.recurrence && data.recurrence.endAt ? formatTime(data.recurrence.endAt) : '-'}
                  </h6>
                </div>
              )}

              {isGroup ? (
                <div className="col-12 mb-1">
                  <FormLabel label="Start offset "></FormLabel>
                  <h6 className="fw-bold">
                    {!!data.recurrence && data.recurrence.offset !== undefined ? data.recurrence.offset : '-'} minutes
                  </h6>
                </div>
              ) : null}
            </>
          )}

          {!isOnboarding && data.notifications?.reminders && (
            <>
              <div className="col-12 mb-1">
                <FormLabel label="Reminder on activation"></FormLabel>
                <h6 className="fw-bold">{data.notifications.reminders.start[currentLanguageIsoCode]}</h6>
              </div>
              <div className="col-12 mb-1">
                <FormLabel label="End reminder"></FormLabel>
                <h6 className="fw-bold">{data.notifications.reminders.end[currentLanguageIsoCode]}</h6>
              </div>
              <div className="col-12 mb-1">
                <FormLabel label="End reminder time"></FormLabel>
                <h6 className="fw-bold">
                  {data?.notifications?.reminders?.endTime ? formatTime(data.notifications.reminders.endTime) : '-'}
                </h6>
              </div>
            </>
          )}

          <div className="col-12 mb-1">
            <FormLabel label="Onboarding"></FormLabel>
            <h6 className="fw-bold">{data ? (data.onboarding === true ? 'Yes' : 'No') : '-'}</h6>
          </div>

          <div className="col-12">
            <FormLabel label="Questions"></FormLabel>
          </div>

          <div className="col-12 px-0 mt-2 mb-4 px-3">
            {data.questions &&
              data.questions.map((question: any, i: number) => (
                <QuestionItem item={question} language={currentLanguageIsoCode!} key={i} />
              ))}
          </div>

          {data.eligibility ? (
            <div className="col-12 mt-2 px-3 py-4 rounded-2" style={{ background: '#f1f1f1' }}>
              <div className="row" style={{}}>
                <div className="col-12">
                  <h6 className="">Eligibility calculation</h6>

                  <hr className="mb-2 mt-3" />
                </div>
              </div>
              <div className="row" style={{}}>
                {eligibilityConfig.map((item, index) => {
                  return <FormViewItem key={index} item={item}></FormViewItem>
                })}
              </div>
            </div>
          ) : null}

          <div className="col-12 mt-3 d-flex justify-content-end">
            <Button className="me-2" onClick={navigateBack}>
              Cancel
            </Button>

            {data?.status === RESOURCE_STATUS.DRAFT && isGroup === false ? (
              <Link to="edit">
                <Button type="primary">Edit Questionnaire</Button>
              </Link>
            ) : null}

            {isGroup ? (
              <>
                {data.status === RESOURCE_STATUS.ACTIVE ? (
                  <Link to="clone">
                    <Button type="primary">Clone questionnaire</Button>
                  </Link>
                ) : (
                  <Link to="edit">
                    <Button type="primary">Edit Questionnaire</Button>
                  </Link>
                )}
              </>
            ) : null}
          </div>
        </div>
      )}
    </div>
  )
}

const QuestionItem = ({ item, language, to = './' }) => {
  const isNprsQuestion = useMemo(() => {
    return (
      item?.type === QuestionsTypes.nprs11pt2l ||
      item?.type === QuestionsTypes.nprs11pt5l ||
      item?.type === QuestionsTypes.nprs5
    )
  }, [item])

  return (
    <div
      className="row pb-2 bg-white mt-2"
      style={{
        border: '1px solid',
        borderColor: '#CFD3D7 ',
        boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.08)',
      }}
    >
      <div className="col-12 pt-3">
        <div className="row">
          <div className="col-12 pb-2 px-2 d-flex align-content-end">
            <p className="p-mini opacity-50">{item._id}</p>
          </div>
          <div className="col-12 pb-2 px-2">
            <p className="mb-0 p-mini alpha-80">Title</p>
            <p className="me-2 fw-bold text-truncate">
              {typeof item.title === 'object' ? item.title[language] : item.title}
            </p>
          </div>
          <div className="col-12 pb-2 px-2">
            <p className="mb-0 p-mini alpha-80">Body</p>
            <p className="mb-1 p-small">{item.body[language]}</p>
          </div>

          {!isNprsQuestion && item?.options?.length > 0 && (
            <div className="col-12 px-2">
              <p className="mb-0 p-mini alpha-80">Options</p>
              <ul className="p-small" style={{ listStyleType: 'none', paddingLeft: '5px' }}>
                {item.options.map((option, index) => (
                  <li className="" key={`option-${index}`}>
                    {'- ' + option[language]}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {isNprsQuestion && item?.options?.classification?.length && (
            <div className="col-12 px-2">
              <p className="mb-0 p-mini alpha-80">Option labels</p>
              <ul className="p-small" style={{ listStyleType: 'none', paddingLeft: '5px' }}>
                {item.options.classification.map((option, index) => (
                  <li className="" key={`option-${index}`}>
                    {`- ` + option[language]}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {item?.correlations?.bodypart?.length > 0 && (
            <div className="col-12 px-2">
              <p className="mb-0 p-mini alpha-80">Body parts</p>
              <ul className="p-small" style={{ listStyleType: 'none', paddingLeft: '5px' }}>
                {item?.correlations?.bodypart?.map((part: any, i: number) => (
                  <li className="" key={`bodypart-${i}`}>
                    {'- ' + BODY_PART_LABELS[part]}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-8">
            <div className="d-flex justify-content-between">
              <div className="">
                <p className="mb-0 p-mini alpha-80 text-start">Type</p>
                <p className="mb-0 fw-bold p-small text-primary text-capitalize">
                  {item.type ? item.type.toLowerCase() : '-'}
                </p>
              </div>
              <div className="ps-2">
                <p className="mb-0 p-mini alpha-80 text-center">Has next</p>
                <p className="mb-0 fw-bold text-primary text-capitalize text-start text-xl-center">
                  {item.next && item?.next.length > 0 ? (
                    <CheckCircleOutlined style={{ color: '#198754' }} />
                  ) : (
                    <CloseCircleOutlined style={{ color: 'red' }} />
                  )}
                </p>
              </div>
              <div className="ps-2">
                <p className="mb-0 p-mini alpha-80 text-center">Required</p>
                <p className="mb-0 fw-bold text-primary text-capitalize text-start text-xl-center">
                  {item?.required === true ? (
                    <CheckCircleOutlined style={{ color: '#198754' }} />
                  ) : (
                    <CloseCircleOutlined style={{ color: 'red' }} />
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const EligibilityComp = ({ eligibilityToggle, eligibilityConfigObj, formHook }) => {
  return eligibilityToggle ? (
    <div className="col-12 mt-2 px-3 py-4 rounded-2" style={{ background: '#f1f1f1' }}>
      <div className="row" style={{}}>
        <div className="col-12">
          <h6 className="mb-1">Eligibility calculation</h6>
          <p className="opacity-75 mb-3">Configure parameters for calculating eligibility</p>
          <hr className="my-2" />
        </div>
      </div>
      <div className="row" style={{}}>
        {eligibilityConfigObj.map((item, index) => {
          const comp = getFormType(item, index, formHook)
          return comp
        })}
      </div>
    </div>
  ) : null
}
