/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28/03/2023.
 */
import { TimePicker } from 'antd'
import React, { useEffect } from 'react'
import { FormLabel } from './FormLabel'
import { get, UseFormReturn } from 'react-hook-form'
import { TimeFormConfigObject } from '../../Types'
import dayjs from 'dayjs'

type Props = { formHook: UseFormReturn; data: TimeFormConfigObject }

const format = 'HH:mm'

const convertFormat = (value) => {
  return value
}

export const FormTime = ({ data, formHook }: Props) => {
  const {
    register,
    setValue,
    getValues,
    unregister,
    clearErrors,
    formState: { errors },
  } = formHook

  const outputFormat = data.outputFormat ? data.outputFormat : 'h:m'

  const errorState = get(errors, data.id)
  const errorMessage: string =
    errorState && errorState.message ? (errorState.message as string) : (data.errorMsg as string)

  useEffect(() => {
    register(data.id, { ...data.validation, value: convertFormat(data.value) || 0 })
  }, [register, data.id, data.validation, data.value])

  useEffect(() => {
    return () => {
      unregister(data.id)
    }
  }, [unregister, data.id])

  const handleChange = (value, time) => {
    if (outputFormat === 'minutes') {
      const minutes = value.get('h') * 60 + value.get('m')
      const outputValue = outputFormat === 'minutes' ? minutes : time
      setValue(data.id, outputValue)
    }

    if (outputFormat === 'HH:mm') {
      setValue(data.id, time)
    }

    //
    // the clearErrors acts a magic bullet, because it keeps the component in sync
    // otherwise the selected value is not show
    //
    // Not sure who original made it - but we should conside putting the value into state
    //
    clearErrors(data.id)
  }

  const getCurrentValue = () => {
    const formValue = getValues(data.id)
    const currentValue = formValue || formValue === 0 ? formValue : data.value
    //
    // Be specific about the value, because it can be 0
    //
    if (!currentValue && currentValue !== 0) {
      return currentValue ? dayjs(currentValue, format) : null
    }

    if (outputFormat === 'minutes') {
      const d = new Date()
      const mustBeNumber = currentValue || 0
      d.setHours(0, mustBeNumber, 0, 0)

      return dayjs(d)
    }

    if (outputFormat === 'HH:mm') {
      return currentValue ? dayjs(currentValue, format) : null
    }

    throw new Error('Invalid outputFormat')
  }

  return (
    <div className={` ${data.className} py-2`}>
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>

      <TimePicker
        className="w-100"
        value={getCurrentValue()}
        placeholder={data.placeholder}
        format={format}
        minuteStep={15}
        showNow={false}
        onChange={handleChange}
        status={errorState ? 'error' : ''}
        changeOnBlur={true}
      />
      {errorState ? <div className="p-small text-danger">{errorMessage}</div> : null}
    </div>
  )
}
