/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/04/2022.
 * Edited and reshaped into being translatable by Søren Tramm on 31/08/2023.
 */
import { Input } from 'antd'
import React, { ChangeEvent, useEffect } from 'react'
import { FormLabel } from './FormLabel'
import { get, UseFormReturn } from 'react-hook-form'
import { LanguageISOCode, LanguageType, TextFormConfigObjectTranslation } from '../../Types'
import { LANGUAGE_LABELS } from '../../constants'

type Props = {
  formHook: UseFormReturn
  data: TextFormConfigObjectTranslation
  languages: LanguageType[] | undefined
  currentLanguage?: LanguageISOCode
}

export const FormTextFieldTranslation = ({ data, formHook, languages, currentLanguage }: Props) => {
  const {
    register,
    setValue,
    getValues,
    unregister,
    formState: { errors },
  } = formHook

  const getDefaultValue = (
    dataValue: string | number | { [key: string]: string | number },
    languages: LanguageType[] = []
  ) => {
    // Return the value untouched if value is an object
    if (typeof dataValue === 'object') {
      return dataValue
    }
    // Build default value for all languages if value is a number or string
    // Prefix only (non-empty) string values with the isoCode
    return languages.reduce(
      (acc, { isoCode }) => ({
        ...acc,
        [isoCode]:
          dataValue === ''
            ? ''
            : typeof dataValue === 'number'
            ? String(dataValue)
            : `${isoCode.toUpperCase()} - ${dataValue}`,
      }),
      {} as { [key: string]: string }
    )
  }

  const getCurrentValue = (
    formHookValue: { [key: string]: string | number } | undefined,
    dataValue: string | number | { [key: string]: string | number } | undefined,
    isoCode: string
  ): string => {
    // Check if getValues(data.id) exists
    if (formHookValue) {
      return String(formHookValue[isoCode] || '')
    }

    // Return an empty string if data.value is undefined, null, or empty string
    if (dataValue === undefined || dataValue === null || dataValue === '') {
      return ''
    }

    // Return value based on language if value is an object.
    // Convert to string if value is a number
    // Return empty string if the value does not exist for the isocode
    if (typeof dataValue === 'object') {
      return String(dataValue[isoCode] || '')
    }

    // Convert to string if value is a number. Return without isoCode prefix
    if (typeof data.value === 'number') {
      return String(data.value)
    }

    // Prefix with isoCode. Will only happen if value is a string
    return `${isoCode.toUpperCase()} - ${dataValue}`
  }

  /**
   * Register as many formhook fields as there are available languages
   */
  useEffect(() => {
    const fields = languages?.map((lang) => `${data.id}.${lang.isoCode}`) ?? []

    // Register fields
    fields.forEach((field) => {
      register(field, { ...data.validation })
    })
  }, [register, languages, data.id, data.validation])

  useEffect(() => {
    const fields = languages?.map((lang) => `${data.id}.${lang.isoCode}`) ?? []

    // Unregister fields when the component unmounts
    return () => unregister(fields)
  }, [unregister, languages, data.id])

  /**
   * Build the default values for all languages from the config value
   * Set the default values in the formhook as one object covering all languages
   */
  useEffect(() => {
    if (data.value !== undefined && data.value !== null) {
      // Build default value
      const value = getDefaultValue(data.value, languages)
      setValue(data.id, value)
    }
  }, [data.id, data.value, languages, setValue])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e?.target?.value || ''

    // Not sure why this guard is necessary, but the original code had it
    if (!currentLanguage) {
      return
    }

    const id = `${data.id}.${currentLanguage}`
    setValue(id, val)
  }

  /*
  Error handling
  */
  type ErrorState = {
    [key: string]: { message: string; type: string; ref: { name: string; value: string } }
  }
  const errorState = get(errors, data.id)
  const compileErrorMessage = (errorState: ErrorState, dataErrMsg: string): string => {
    const message = errorState?.message || dataErrMsg
    return `${message}: ${Object.keys(errorState)
      .map((key) => LANGUAGE_LABELS[key])
      .join(', ')}`
  }

  const currentValue = getCurrentValue(getValues(data.id), data.value, currentLanguage!)

  return (
    <div className="col-12 w-100 py-2">
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p className="mb-1">
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>
      <Input
        key={data.id + '-' + currentLanguage}
        placeholder={currentLanguage ? currentLanguage.toUpperCase() + ' - ' + data.placeholder : ''}
        onChange={onChange}
        defaultValue={currentValue}
        status={errorState ? 'error' : ''}
      />
      {errorState ? <div className="p-small text-danger">{compileErrorMessage(errorState, data.errorMsg)}</div> : null}
    </div>
  )
}
