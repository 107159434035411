import { WarningOutlined } from '@ant-design/icons'
import React, { ReactNode } from 'react'

export const LanguageWarning = ({ label, children }: { label: string; children?: ReactNode | ReactNode[] }) => {
  return (
    <div className="px-3 py-2 mb-2" style={{ background: '#FFEBA9' }}>
      <div className="d-flex align-items-center">
        <WarningOutlined className="me-3" style={{ fontSize: '2rem' }} />
        <div>
          <p className="fw-bold">{label}</p>
          <div>{children}</div>
        </div>
      </div>
    </div>
  )
}
