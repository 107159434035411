/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 29/03/2023.
 */
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { post } from '../../service/API'
import { Alert, Button } from 'antd'
import { getFormType } from '../../components/form/formFactory'
import { populateConfig } from '../../util/populateConfig'
import { getPhaseConfig } from './phaseConfig'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { CRUD_VIEW_TYPE, VIEW_TYPE } from '../../Types'
import { useByEndpoint } from '../../hooks/useByEndpoint'
import { useSWRConfig } from 'swr'
import { openNotification } from '../../components/notifications/openNotification'
import { useProjectConfig } from '../../hooks/useProjectConfig'
import { PhaseView } from './PhaseView'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
  allowEdit?: boolean
}

export const PhaseCRUD = ({ closeDrawer, onClose, viewType = CRUD_VIEW_TYPE.CREATE, allowEdit = true }: Props) => {
  const { projectId, phaseId, protocolId } = useParams() as { projectId: string; phaseId: string; protocolId: string }
  const { mutate: globalMutate } = useSWRConfig()

  const p = phaseId ? ENDPOINTS.PHASES.byPhaseId(projectId, phaseId) : ''
  const { data, mutate } = useByEndpoint(p)

  const { data: projectConfig } = useProjectConfig()

  const bodyParts = projectConfig?.bodyParts
  const phaseTypes = projectConfig?.phaseTypes

  //
  // build config object - to populate form
  //
  const configObj =
    viewType === 'UPDATE'
      ? populateConfig(getPhaseConfig(bodyParts, phaseTypes), data, viewType)
      : getPhaseConfig(bodyParts, phaseTypes)

  const formHook = useForm()

  const { handleSubmit } = formHook
  const [errorState, setErrorState] = useState<any>()

  const onSubmit = (formData, e) => {
    e.preventDefault()

    const createData = {
      ...formData,
      projectId: projectId,
      protocolId: protocolId,
      bodyParts: formData.bodyParts === '' ? [] : formData.bodyParts,
    }
    const updatedData = { ...data, ...formData, protocolId: protocolId }

    const method = viewType === 'UPDATE' ? 'PUT' : 'POST'
    const sendData = viewType === 'UPDATE' ? updatedData : createData

    return post(ENDPOINTS.PHASES.ACTION(projectId), sendData, method)
      .then((result) => {
        const msg = viewType === 'UPDATE' ? 'Phase updated' : 'Phase created'

        openNotification(msg, '', 'happy')

        mutate()
        globalMutate(ENDPOINTS.PROTOCOLS.GET_ONE(projectId, protocolId))

        if (onClose) {
          onClose()
        }

        if (closeDrawer) {
          closeDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      {viewType === 'CREATE' || viewType === 'UPDATE' ? (
        <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="col-12">
            {configObj.map((item, index) => {
              const comp = getFormType(item, index, formHook)
              return comp
            })}
          </div>

          <div className="col-12 mt-3">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>

          <div className="col-12 d-flex justify-content-end mt-3">
            <Button className="me-2" onClick={navigateBack}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
              {viewType === 'CREATE' ? 'Create new phase' : 'Save changes'}
            </Button>
          </div>
        </form>
      ) : (
        <PhaseView data={data}>
          {allowEdit ? (
            <div className="col-12 d-flex justify-content-end">
              <Link to="edit">
                <Button type="primary">Edit</Button>
              </Link>
            </div>
          ) : null}
        </PhaseView>
      )}
    </>
  )
}
