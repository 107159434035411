/**
 * Created by @author Søren Tramm
 */
import { GroupHeader } from '../../components/display/GroupHeader'
import { Button } from 'antd'
import React, { Suspense, useCallback } from 'react'
import { Link, Route, Routes, useParams } from 'react-router-dom'

import { useAdmins } from '../../hooks/useAdmins'
import { ProjectDrawer } from '../epro-project/ProjectDrawer'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { AuditViewList } from './AuditViewList'
import { AuditViewDetails } from './AuditViewDetails'
import { LoginViewListAdmin } from './LoginViewListAdmin'
import { useAuditByTarget } from '../../hooks/useAuditByTarget'
import { useAuditProtocolByProject } from '../../hooks/useAuditProtocolByProject'
import { AUDIT_TYPES } from '../../Types'
import { genericDataTimeformat } from '../../util/datetime'

export const AuditTrailPage = () => {
  const { projectId } = useParams() as { projectId: string }
  const { data, mutate } = useAdmins()
  const { data: projectAuditData } = useAuditByTarget(projectId, projectId) // Project is the target
  const { data: protocolAuditData } = useAuditProtocolByProject(projectId) // Project is the target

  const closeDrawer = (value) => {
    if (!value) {
      mutate()
    }
  }

  const getStatusUpdateMessage = (statusBefore: string, statusAfter: string) => (
    <div>
      <span className="opacity-75">updated the project status </span>
      <span className="opacity-75"> from </span>
      <span className="fw-bold">{statusBefore}</span>
      <span className="opacity-75"> to </span>
      <span className="fw-bold">{statusAfter}</span>
    </div>
  )

  // Render project audit data
  const auditProjectTypeContent = useCallback((item: any) => {
    const auditRender = {
      [AUDIT_TYPES.ACTIVATE_PROJECT]: getStatusUpdateMessage(item.before?.status, item.after?.status),
      [AUDIT_TYPES.RETIRE_PROJECT]: getStatusUpdateMessage(item.before?.status, item.after?.status),
      [AUDIT_TYPES.ARCHIVE_PROJECT]: getStatusUpdateMessage(item.before?.status, item.after?.status),
      [AUDIT_TYPES.UPDATE_PROJECT]: (
        <div>
          <span className="opacity-75">updated the project. </span>
          <span className="opacity-75">
            {item?.after?.activeLanguages ? 'Activate languages are: ' : 'No active languages'}
          </span>
          <span className="fw-bold">{item?.after?.activeLanguages && item?.after?.activeLanguages}</span>
        </div>
      ),
      [AUDIT_TYPES.ADD_LANGUAGE_TO_PROJECT]: (
        <div>
          <span className="opacity-75">added following languages: </span>
          {/*<span className="opacity-75">Following languages have been added: </span>*/}
          <span className="fw-bold">{item?.after}</span>
        </div>
      ),
      [AUDIT_TYPES.CREATE_PROJECT]: <span className="opacity-75">created the project</span>,
    }
    if (!auditRender[item.auditType]) return <span className="text-danger">Unknown audit type</span>
    return auditRender[item.auditType]
  }, [])

  // Render protocol audit data
  const auditProtocolTypeContent = useCallback((item: any) => {
    const statusElement = (
      <div className="d-flex">
        <div>
          <span className="opacity-75">moved the protocol&nbsp;</span>
          <span className="fw-bold">{item.targetUniqueId}&nbsp;</span>
        </div>
        <div>
          <span className="opacity-75">from&nbsp;</span>
          <span className="fw-bold">{item?.before?.status}&nbsp;</span>
          <span className="opacity-75">to&nbsp;</span>
          <span className="fw-bold">{item?.after?.status}</span>
        </div>
      </div>
    )
    const auditRender = {
      [AUDIT_TYPES.ACTIVATE_PROTOCOL]: statusElement,
      [AUDIT_TYPES.RETIRE_PROTOCOL]: statusElement,
      [AUDIT_TYPES.CLONE_PROTOCOL]: (
        <div>
          <span className="opacity-75">cloned the protocol to </span>
          <span className="fw-bold">{item.targetUniqueId}&nbsp;</span>
        </div>
      ),
      [AUDIT_TYPES.CREATE_PROJECT]: <span className="opacity-75">created the project</span>,
    }
    if (!auditRender[item.auditType]) return <span className="text-danger">Unknown audit type: {item.auditType}</span>
    return auditRender[item.auditType]
  }, [])

  return (
    <>
      <Routes>
        <Route
          path="/author/:authorId/*"
          element={
            <ProjectDrawer projectId={projectId} setVisible={closeDrawer} to="../" title="Audit trail list">
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <AuditViewList closeDrawer={closeDrawer}></AuditViewList>
                <Routes>
                  <Route
                    path="/detail/:auditId"
                    element={
                      <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                        <ProjectDrawer projectId={projectId} width="30%" to="../" title="Audit trail">
                          <AuditViewDetails closeDrawer={closeDrawer} />
                        </ProjectDrawer>
                      </Suspense>
                    }
                  />
                </Routes>
              </Suspense>
            </ProjectDrawer>
          }
        />
        <Route
          path="/audit-id/:auditId"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectDrawer projectId={projectId} width="30%" setVisible={closeDrawer} to="../" title="Audit details">
                <AuditViewDetails closeDrawer={closeDrawer} />
              </ProjectDrawer>
            </Suspense>
          }
        />
        <Route
          path="/logins/:adminId/*"
          element={
            <ProjectDrawer width="50%" projectId={projectId} setVisible={closeDrawer} to="../" title="Login list">
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <LoginViewListAdmin closeDrawer={closeDrawer} />
              </Suspense>
            </ProjectDrawer>
          }
        />
      </Routes>

      <div className="row mt-5">
        <h4>Audit trail</h4>
        <p className="p-small">View actions performed by admins for this project</p>
      </div>

      {/*ADMIN USER*/}
      <div className="row mt-5 group-item" style={{}}>
        <GroupHeader
          label="Admin users"
          byline="You can click 'Audit trail' to see what audits has been logged for the admin."
        >
          {data.map((admin, index) => {
            return (
              <div key={index} className="col-12 py-3 border-bottom d-flex justify-content-between align-items-center">
                <div className="" style={{ width: '75%' }}>
                  <p className="p-mini">{admin._id}</p>
                  <div className="d-flex">
                    <p className="text-truncate opacity-75">{admin.email}</p>
                  </div>
                </div>

                <div className="d-flex justify-content-end" style={{ width: '25%' }}>
                  <Link to={'./logins/' + admin._id}>
                    <Button>Logins</Button>
                  </Link>
                  <Link to={'./author/' + admin.sub} className="ms-2">
                    <Button>Audit trail</Button>
                  </Link>
                </div>
              </div>
            )
          })}
        </GroupHeader>
      </div>

      {/*PROJECT*/}
      <div className="row mt-5 group-item" style={{}}>
        <GroupHeader label="Project Audit trail" byline="View changes made to the project">
          {projectAuditData.length === 0 ? (
            <div className="my-4">
              <p>No audit data exists for this project</p>
            </div>
          ) : null}

          {projectAuditData.map((item, index) => {
            const actionTime = genericDataTimeformat(item.createdAt) //format(new Date(item.createdAt), 'E d LLL yyyy HH:mm:ss ')

            return (
              <div key={index} className="col-12 py-4 border-bottom d-flex justify-content-between align-items-center">
                <div className="" style={{ width: '75%' }}>
                  <p className="p-small ">{actionTime}</p>

                  <div className="d-flex">
                    <div className="me-1">
                      <span className="fw-bold">{item.authorEmail}</span>
                    </div>
                    {auditProjectTypeContent(item)}
                  </div>
                </div>

                <div className="d-flex justify-content-end" style={{ width: '25%' }}>
                  <Link className="me-2" to={'./audit-id/' + item._id}>
                    <Button>View details</Button>
                  </Link>
                </div>
              </div>
            )
          })}
        </GroupHeader>
      </div>

      {/*PROTOCOL*/}
      <div className="row mt-5 group-item" style={{}}>
        <GroupHeader label="Protocol Audit trail" byline="View changes made to the protocol">
          {protocolAuditData.length === 0 ? (
            <div className="my-4">
              <p>No audit data exists for this project's protocol</p>
            </div>
          ) : null}

          {protocolAuditData.map((item, index) => {
            const actionTime = genericDataTimeformat(item.createdAt)

            return (
              <div key={index} className="col-12 py-4 border-bottom d-flex justify-content-between align-items-center">
                <div className="" style={{ width: '75%' }}>
                  <p className="p-small ">{actionTime}</p>

                  <div className="d-flex">
                    <div className="me-1">
                      <span className="fw-bold">{item.authorEmail}</span>
                    </div>
                    {auditProtocolTypeContent(item)}
                  </div>
                </div>

                <div className="d-flex justify-content-end" style={{ width: '25%' }}>
                  <Link className="me-2" to={'./audit-id/' + item._id}>
                    <Button>View details</Button>
                  </Link>
                </div>
              </div>
            )
          })}
        </GroupHeader>
      </div>
    </>
  )
}
