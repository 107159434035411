/**
 * Created by @author Søren Tramm
 */
import { FormLabel } from '../../components/form/FormLabel'
import { RESOURCE_STATUS } from '../../Types'
import React from 'react'
import { BODY_PART_LABELS } from '../../constants'

type Props = {
  data: {
    title: string
    type: string
    status: keyof typeof RESOURCE_STATUS
    duration: number
    bodyParts: string[]
    shortCompliance: number
    overallCompliance: number
  }
  children: React.ReactNode
}

export const PhaseView = ({ data, children }: Props) => {
  if (!data) {
    return null
  }

  const getBodyPartLabel = (value: string) => {
    return BODY_PART_LABELS[value] ?? 'Missing label'
  }

  return (
    <div className="container-fluid px-0">
      <div className="row" style={{}}>
        <div className="col-12 mb-4">
          <FormLabel label="Phase title"></FormLabel>
          <h6 className="fw-bold">{data ? data.title : 'NaN'}</h6>
        </div>
        <div className="col-12 mb-4">
          <FormLabel label="Phase duration"></FormLabel>
          <h6 className="fw-bold">{data ? data.duration + ' days' : 'NaN'}</h6>
        </div>
        <div className="col-12 mb-4">
          <FormLabel label="Phase type"></FormLabel>
          <h6 className="fw-bold">{data ? data.type : 'NaN'}</h6>
        </div>

        <div className="col-12 mb-4">
          <FormLabel label="Body parts"></FormLabel>
          {data.bodyParts
            ? data.bodyParts.map((bodyPart, index) => <h6 key={index}>{getBodyPartLabel(bodyPart)}</h6>)
            : null}
        </div>

        <div className="col-12 mb-4">
          <FormLabel label="Short compliance trigger (days)"></FormLabel>
          <h6 className="fw-bold">{data.shortCompliance ? data.shortCompliance : '-'}</h6>
        </div>
        <div className="col-12">
          <FormLabel label="Overall compliance trigger (%)"></FormLabel>
          <h6 className="fw-bold">{data.overallCompliance ? data.overallCompliance + '%' : '-'}</h6>
        </div>
        <div className="col-12 mb-2">
          <hr />
        </div>
        {children}
      </div>
    </div>
  )
}
