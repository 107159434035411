import { Alert, Button, List, Switch } from 'antd'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { CRUD_VIEW_TYPE, LanguageType, VIEW_TYPE } from '../../Types'
import { FormLabel } from '../../components/form/FormLabel'
import { JustificationInput } from '../../components/form/JustificationInput'
import { getFormType } from '../../components/form/formFactory'
import { openNotification } from '../../components/notifications/openNotification'
import { useProject } from '../../hooks/useProject'
import { useProjectConfig } from '../../hooks/useProjectConfig'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { populateConfig } from '../../util/populateConfig'
import { getSiteConfig } from './siteConfig'
import { useSiteWithLocalTime } from '../../hooks/useSiteWithLocalTime'
import { sortBy } from 'lodash'
import CheckCircleOutlined from '@ant-design/icons/lib/icons/CheckCircleOutlined'
import { MinusCircleOutlined } from '@ant-design/icons'

/**
 * Re. Languages
 * New paradigm.
 * ONLY languages that are ACTIVE on a site are saved in the site's language list
 * There will be a backwards compatibility issue since there are sites having languages
 * saved that has an active state set til false and there is surely code that rely on that throughout the system.
 * Hence, the active state MUST be set to be TRUE for languages saved on the site
 *
 * Again:
 * - Only language that are considered to be active on a site are saved in the site's language list
 * - A language's active state must be set to "true" when saved on a site. For backwards compatibility
 * - A site's language list is a subset of a projects language list
 *
 * Languages to display for view types:
 * - CREATE
 * -- We list all the project languages
 * -- The language object is taken from the project language list
 * -- Active state is forced to be false per default
 *
 * - UPDATE
 * -- We list all the project languages
 * -- The language object is taken from the project language list
 * -- Active state is set to true if:
 * --- it is present in the site's language list
 * --- and active state is set to true
 * -- Else the active state is set to be false
 *
 * -VIEW
 * -- We show only the languages saved on the site
 */

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
}

export const SiteCRUD = ({ closeDrawer, onClose, viewType = 'CREATE' }: Props) => {
  const { projectId, siteId } = useParams() as { projectId: string; siteId: string }

  const { data, mutate } = useSiteWithLocalTime(projectId, siteId, !!siteId)
  const { data: dataConfig } = useProjectConfig()
  const { data: projectData } = useProject(projectId)

  // updatedLangData is the updated list of language objects eventually submitted to backend
  const [siteLanguages, setSiteLanguages] = useState<LanguageType[]>(data?.languages || [])

  const [localSiteDate, localSiteTime] = (data?.localTime || '-, -').split(', ')

  const config =
    viewType === 'UPDATE'
      ? populateConfig(getSiteConfig(dataConfig?.timezones), data, viewType)
      : getSiteConfig(dataConfig?.timezones)

  const formHook = useForm()
  const { handleSubmit } = formHook
  const [errorState, setErrorState] = useState<any>()

  const onSubmit = (formData, e) => {
    e.preventDefault()
    if (!isValidSiteNumber(formData.siteNumber)) {
      setErrorState({
        message: 'Site number can contain the lower case letters a to z, the numbers 0 to 9',
      })
      return
    } else {
      setErrorState(null)
    }

    // Filter out the languages that have the active state set to be true
    const langDataToSend = siteLanguages.filter((lang) => lang.active)

    const createData = { ...formData, projectId: projectId, languages: langDataToSend }
    const updatedData = { ...data, ...formData, languages: langDataToSend }
    const method = viewType === 'UPDATE' ? 'PUT' : 'POST'
    const sendData = viewType === 'UPDATE' ? updatedData : createData

    return post(ENDPOINTS.SITES.ACTION(projectId), sendData, method)
      .then((result) => {
        mutate()

        openNotification('Site successfully updated', '', 'happy')
        if (onClose) {
          onClose()
        }
        if (closeDrawer) {
          closeDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        openNotification('Site update failed: ', error.message, 'sad')
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
  }

  const isValidSiteNumber = (value: string) => {
    return /^[a-z0-9]+$/.test(value) // /^(?!.*\s{2,})[a-z0-9\s]+$/.test(value)
  }

  const updateSiteLanguageHandler = (updated: LanguageType[]) => {
    setSiteLanguages(updated)
  }

  return (
    <>
      {viewType === 'CREATE' || viewType === 'UPDATE' ? (
        <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="col-12">
            {config.map((item, index) => {
              // -------------------------------------------------------------------------------------
              // If time allows, investigate how to do it directly through the config (by injecting)
              // -------------------------------------------------------------------------------------
              const comp = getFormType(item, index, formHook, [], undefined, dataConfig?.countries)
              return comp
            })}
          </div>
          <div className="mt-2 mb-3">
            <FormLabel label="Site languages"></FormLabel>
            <ActivateLanguages
              siteLanguages={siteLanguages}
              projectLanguages={projectData.languages}
              onUpdateSiteLanguage={updateSiteLanguageHandler}
            />
          </div>
          {/*Justification*/}
          {viewType === CRUD_VIEW_TYPE.UPDATE ? <JustificationInput formHook={formHook} /> : null}
          <div className="col-12 mt-3">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>
          <div className="col-12 d-flex justify-content-end mt-3">
            <Button className="me-2" onClick={navigateBack}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
              {viewType === 'CREATE' ? 'Create site' : 'Save changes'}
            </Button>
          </div>
        </form>
      ) : (
        <div className="">
          <div className="col-12 mb-4">
            <div className="col-12 mb-4">
              <FormLabel label="Site number"></FormLabel>
              <h6 className="fw-bold">{data?.siteNumber || '-'}</h6>
            </div>
            <FormLabel label="Site name"></FormLabel>
            <h6 className="fw-bold">{data?.title || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site address"></FormLabel>
            <h6 className="fw-bold">{data?.address || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site phone number"></FormLabel>
            <h6 className="fw-bold">{data.phone ? data.phone.countryCode + ' ' + data.phone.tel : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site contact person name"></FormLabel>
            <h6 className="fw-bold">{data?.contactPersonName || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site contact person email"></FormLabel>
            <h6 className="fw-bold">{data?.contactPersonEmail || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site contact person job title"></FormLabel>
            <h6 className="fw-bold">{data?.contactPersonJobTitle || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Time zone"></FormLabel>
            <h6 className="fw-bold">{data?.timeZone || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site local date"></FormLabel>
            <h6 className="fw-bold">{localSiteDate ? localSiteDate : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site local time (24-hour clock)"></FormLabel>
            <h6 className="fw-bold">{localSiteTime ? localSiteTime : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Active"></FormLabel>
            <h6 className="fw-bold">{data?.active === true ? 'Yes' : 'No' || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site languages"></FormLabel>
            <div className="border rounded-3">
              <div className="d-flex px-3 py-2" style={{ background: '#f3f3f3' }}>
                <div className="" style={{ width: '50%' }}>
                  <span className="fw-bold">Language</span>
                </div>
                <div className="d-flex justify-content-center" style={{ width: '25%' }}>
                  <span className="fw-bold">Site</span>
                </div>
                <div className="d-flex justify-content-center" style={{ width: '25%' }}>
                  <span className="fw-bold">Project</span>
                </div>
              </div>
              <div className="border-top">
                <List
                  className="mb-2 px-0"
                  size="small"
                  dataSource={sortBy(projectData.languages, 'name')}
                  renderItem={(projectLanguage: LanguageType) => {
                    const isActiveOnSite = data?.languages.some(
                      (siteLanguage) => projectLanguage.isoCode === siteLanguage.isoCode && siteLanguage.active
                    )
                    return (
                      <List.Item className="d-flex justify-content-between">
                        <div className="d-flex text-truncate overflow-hidden" style={{ width: '50%' }}>
                          <div className="">{projectLanguage.name}</div>
                        </div>

                        <div className="d-flex justify-content-center" style={{ width: '25%' }}>
                          {isActiveOnSite ? (
                            <CheckCircleOutlined style={{ fontSize: '18px', color: '#526b5b' }} />
                          ) : (
                            <MinusCircleOutlined style={{ fontSize: '18px', color: '#dcdcdc' }} />
                          )}
                        </div>

                        <div className="d-flex justify-content-center" style={{ width: '25%' }}>
                          {projectLanguage.active ? (
                            <CheckCircleOutlined style={{ fontSize: '18px', color: '#526b5b' }} />
                          ) : (
                            <MinusCircleOutlined style={{ fontSize: '18px', color: '#dcdcdc' }} />
                          )}
                        </div>
                      </List.Item>
                    )
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end">
            <Link to="edit">
              <Button type="primary">Edit site</Button>
            </Link>
          </div>
        </div>
      )}
    </>
  )
}

const ActivateLanguages = ({
  projectLanguages,
  siteLanguages,
  onUpdateSiteLanguage,
}: {
  projectLanguages: LanguageType[]
  siteLanguages: LanguageType[]
  onUpdateSiteLanguage: (updated) => void
}) => {
  /*
               Backwards compatibility.
                The language list on sites has been changed from having all projects languages saved on the site and the active flag set to true/false - to
                that only the languages that are active on the site are saved on the site.
                That means that we need to handle the language list as if there might be languages on the site with have the active flag set to be false.
                So when adding a language we check if the language is already there. If it is we set the activate state to true. If it's not we add it and add set the active state to true
    
                Find the language in the site's language list - if it exists, set the active flag to true
                If it does not exist in the list, add the language
            */
  const onChange = (isoCode: string, checked: boolean) => {
    // Add
    if (checked) {
      const existingSiteLanguage = siteLanguages.find((lang: LanguageType) => lang.isoCode === isoCode)

      if (existingSiteLanguage) {
        // If the language is found in siteLanguages, mark it active
        existingSiteLanguage.active = true
      } else {
        // If the language isn't in siteLanguages, find it in projectLanguages
        const newLanguage = projectLanguages.find((lang: LanguageType) => lang.isoCode === isoCode)

        if (newLanguage) {
          // If the language is found in projectLanguages, add it to siteLanguages
          siteLanguages.push({ ...newLanguage, active: true }) // Add a copy of the language object to avoid that the original is mutated
        }
      }

      // Update the siteLanguages
      onUpdateSiteLanguage([...siteLanguages]) // A shallow copy ensures a correct re-render of the component

      // Remove
    } else {
      const updated = siteLanguages.filter((lang) => lang.isoCode !== isoCode)
      onUpdateSiteLanguage(updated)
    }
  }

  return (
    <div className="border rounded-3">
      <div className="d-flex px-3 py-2" style={{ background: '#f3f3f3' }}>
        <div className="" style={{ width: '50%' }}>
          <span className="fw-bold">Language</span>
        </div>
        <div className="d-flex justify-content-center" style={{ width: '25%' }}>
          <span className="fw-bold">Site</span>
        </div>
        <div className="d-flex justify-content-center" style={{ width: '25%' }}>
          <span className="fw-bold">Project</span>
        </div>
      </div>
      <div className="border-top">
        <List
          className="mb-2 px-0"
          size="small"
          dataSource={sortBy(projectLanguages, 'name')}
          renderItem={(projectLanguage: LanguageType) => {
            const isActiveOnSite = siteLanguages.some(
              (siteLanguage) => projectLanguage.isoCode === siteLanguage.isoCode && siteLanguage.active
            )
            return (
              <List.Item className="d-flex justify-content-between">
                <div className="d-flex text-truncate" style={{ width: '50%' }}>
                  <div className="">{projectLanguage.name}</div>
                </div>

                <div className="d-flex justify-content-center" style={{ width: '25%' }}>
                  <Switch
                    className="mt-1"
                    checked={isActiveOnSite}
                    onChange={(checked) => onChange(projectLanguage.isoCode, checked)}
                    size="small"
                  />
                </div>

                <div className="d-flex justify-content-center" style={{ width: '25%' }}>
                  {projectLanguage.active ? (
                    <CheckCircleOutlined style={{ fontSize: '18px', color: '#526b5b' }} />
                  ) : (
                    <MinusCircleOutlined style={{ fontSize: '18px', color: '#dcdcdc' }} />
                  )}
                </div>
              </List.Item>
            )
          }}
        />
      </div>
    </div>
  )
}
