import { LanguageType } from '../Types'
import { difference } from 'lodash'
import { DEFAULT_LANGUAGE_CODES } from '../constants'

export const getDefaultLanguage = (languageList: LanguageType[]) => {
  const code = DEFAULT_LANGUAGE_CODES.find((isoCode) => languageList.some((language) => language.isoCode === isoCode))
  return languageList.find((lang) => lang.isoCode === code) || languageList[0]
}

export function getMissingLanguages(testObject: LanguageType, projectLanguageList: LanguageType[]) {
  const isoCodes = Object.keys(testObject)
  const projectIsoCode = projectLanguageList.map((lang) => lang.isoCode)
  return difference(projectIsoCode, isoCodes)
}
