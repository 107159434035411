/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04/11/2024.
 */
import React, { useState } from 'react'
import { getGroupConfig, getGroupCtaElement } from './groupConfig'
import { CRUD_VIEW_TYPE, RESOURCE_STATUS, VIEW_TYPE } from '../../Types'
import { populateConfig } from '../../util/populateConfig'
import { Link, useParams } from 'react-router-dom'
import { Alert, Button } from 'antd'
import { getFormType } from '../../components/form/formFactory'
import { useForm } from 'react-hook-form'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { openNotification } from '../../components/notifications/openNotification'
import { useProjectConfig } from '../../hooks/useProjectConfig'
import { useGroup } from '../../hooks/useGroup'
import { useSWRConfig } from 'swr'
import { GroupView } from './GroupView'
import { usePhaseById } from '../../hooks/usePhaseById'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType: VIEW_TYPE
  allowEdit?: boolean
}

export const GroupCRUD = ({ closeDrawer, onClose, viewType = CRUD_VIEW_TYPE.CREATE }: Props) => {
  const { projectId, groupId, protocolId, phaseId } = useParams() as {
    projectId: string
    groupId: string
    protocolId: string
    phaseId: string
  }

  const { data: phaseData } = usePhaseById(projectId, phaseId)

  const [errorState, setErrorState] = useState<any>()
  const { mutate: globalMutate } = useSWRConfig()
  const { data: projectConfig } = useProjectConfig()
  const { data, mutate } = useGroup(projectId, groupId)

  //
  // form hook
  //
  const formHook = useForm()
  const { handleSubmit } = formHook

  //
  // build config object - to populate form
  //
  const config = data
    ? populateConfig(getGroupConfig(projectConfig), data, viewType) // FIXME
    : getGroupConfig(projectConfig)

  const ctaConfig = data
    ? populateConfig(getGroupCtaElement, data, viewType) // FIXME
    : getGroupCtaElement

  const onSubmit = (formData, e) => {
    e.preventDefault()

    const createData = {
      ...formData,
      projectId: projectId,
      protocolId: protocolId,
      phaseId: phaseId,
    }
    const updatedData = {
      ...formData,
      groupId: groupId,
      protocolId: protocolId,
      projectId: projectId,
      phaseId: phaseId,
    }

    const method = viewType === CRUD_VIEW_TYPE.UPDATE ? 'PUT' : 'POST'
    const sendData = viewType === CRUD_VIEW_TYPE.UPDATE ? updatedData : createData

    return post(ENDPOINTS.GROUPS.ACTION.create(projectId), sendData, method)
      .then((result) => {
        const msg = viewType === 'UPDATE' ? 'Group updated' : 'Group created'
        openNotification(msg, '', 'happy')
        mutate()
        globalMutate(ENDPOINTS.PROTOCOLS.GET_ONE(projectId, protocolId))

        if (onClose) {
          onClose()
        }

        if (closeDrawer && viewType !== CRUD_VIEW_TYPE.VIEW) {
          closeDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    console.log('onError = ', err)
    e.preventDefault()
  }

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      {phaseData.status === RESOURCE_STATUS.ACTIVE ? (
        <div className="alert alert-danger" role="alert">
          <p>
            <b>This phase is active</b> <br />
            To create a new group, you must first clone the phase.
          </p>
        </div>
      ) : null}

      {viewType === CRUD_VIEW_TYPE.CREATE || viewType === CRUD_VIEW_TYPE.UPDATE ? (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="row">
            {config.map((item, index) => {
              return getFormType(item, index, formHook)
            })}
          </div>

          <div className="row">
            <div className="col-12 px-4 mt-3">
              <div className="row pt-2 pb-3 rounded" style={{ background: '#f3f3f3' }}>
                {ctaConfig.map((item, index) => {
                  return getFormType(item, index, formHook)
                })}
              </div>
            </div>
          </div>

          <div className="col-12 mt-3">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>

          <div className="col-12 d-flex justify-content-end mt-3">
            <Button className="me-2" onClick={navigateBack}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
              {viewType === 'CREATE' ? 'Create group' : 'Save changes'}
            </Button>
          </div>
        </form>
      ) : (
        /*
			--------------
			VIEW Group
			--------------
			*/

        <GroupView data={data} ctaConfig={ctaConfig}>
          <div className="col-12 mt-3 d-flex justify-content-end">
            {data.status === RESOURCE_STATUS.DRAFT ? (
              <Link to="edit">
                <Button type="primary">Edit Group</Button>
              </Link>
            ) : (
              <Button className="me-2" type="primary" onClick={navigateBack}>
                Close
              </Button>
            )}
          </div>
        </GroupView>
      )}
    </>
  )
}
